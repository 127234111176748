import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"

import { formatedDate } from "../../utils/date"
import Arrow from "../../svg/Arrow"

class ContentPreviewInfos extends React.Component {
  constructor(props) {
    super(props)
  }

  meta = () => {
    let data = []
    if (this.props.office != null) {
      data.push(this.props.office.shortName)
    }
    if (this.props.date) {
      data.push(formatedDate(this.props.date))
    }
    return data.join(` - `)
  }

  render() {
    // fade effect or simply wrap with a span for disabling fade-in effects
    const Effect = this.props.reveals ? Fade : `span`

    return (
      <footer className="content__preview__infos">
        <Effect top delay={250} distance="50%">
          {(this.props.office !== null || this.props.date) && (
            <h6 className="office__shortname small-text pride-text">{this.meta()}</h6>
          )}
        </Effect>
        <Effect bottom delay={100} distance="25%">
          <h3 className="content__preview__title pride-text">{this.props.contentTitle}</h3>
        </Effect>
        {this.props.subheadline !== null && (
          <Effect bottom delay={125} distance="25%">
            <p className="content__preview__subheadline pride-text">
              {this.props.subheadline}
            </p>
          </Effect>
        )}
        <Effect bottom delay={150} distance="25%">
          <nav className="content__preview__nav nav-read-more pride-text">
            <span className="btn__arrow on-baseline">
              {this.props.readMoreText} <Arrow />
            </span>
          </nav>
        </Effect>
      </footer>
    )
  }
}

ContentPreviewInfos.propTypes = {
  readMoreText: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  subheadline: PropTypes.string,
  date: PropTypes.string,
  office: PropTypes.object,
  reveals: PropTypes.bool,
}

export default ContentPreviewInfos
