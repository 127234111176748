import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"
import { Player } from "video-react"
import { Lazy } from "react-lazy"
// import { withBreakpoints } from "react-breakpoints"

import FigureBox, { boxFormats } from "../../components/FigureBox"

import { setTransitionType } from "../../actions"
import { connect } from "react-redux"

import { isMobile } from "../../utils/breakpoints"

import ContentPreviewInfos from "./ContentPreviewInfos"

// refer to content_preview.scss file for style details
export const ContentPreviewLayouts = {
  compact: `content__preview__layout__compact`,
  small: `content__preview__layout__small`,
  compact_offset_left: `content__preview__layout__compact__offset__left`,
  compact_offset_right: `content__preview__layout__compact__offset__right`,
  compact_fill: `content__preview__layout__compact_fill`,
  full: `content__preview__layout__full`,
  featured: `content__preview__layout__featured`,
}

class ContentPreview extends React.Component {
  constructor(props) {
    super(props)

    this.handleMouseEnter = this.mouseEnter.bind(this)
    this.handleMouseLeave = this.mouseLeave.bind(this)

    this.contentData =
      this.props.contentData.node !== undefined
        ? this.props.contentData.node
        : this.props.contentData

    this.state = {
      videoPreviewClassName: ``,
    }
  }

  mouseEnter() {
    if (this.refs.videoPreview) {
      this.refs.videoPreview.play()
      this.setState({
        videoPreviewClassName: `is-playing`,
        hoverClassName: `is-hover`,
      })
    } else {
      this.setState({
        hoverClassName: `is-hover`,
      })
    }
  }

  mouseLeave() {
    if (this.refs.videoPreview) {
      this.refs.videoPreview.pause()
      this.setState({
        videoPreviewClassName: ``,
        hoverClassName: ``,
      })
    } else {
      this.setState({
        hoverClassName: ``,
      })
    }
  }

  // Models are not all using 'title' as value, needs patching in Contenful someday.
  contentTitle = () => {
    if (this.contentData.name && !this.contentData.title) {
      return this.contentData.name
    }

    if (this.contentData.title && !this.contentData.name) {
      return this.contentData.title
    }
  }

  findImageSource = source => {
    let type = source.resolutions ? `graphql` : `source`

    switch (type) {
      case `graphql`:
        return source.resolutions
        break
      case `source`:
        // FIXME: hardcoded Contentful image API quality and image width, should become a props
        if (source.file) {
          return { src: `${source.file.url}?fm=jpg&q=85&w=800` }
        } else {
          return { src: null }
        }
        break
      default:
        return { src: null }
        break
    }
  }

  backgroundPosition = () => {
    switch (this.props.contentType) {
      case `work`:
        return `center center`
        break
      case `news`:
        return `center center`
        break
      case `qAndA`:
        return `top center`
      default:
        return `top center`
        break
    }
  }

  figure = () => {
    if (this.contentData.mainImage) {
      return (
        <figure className="content__preview__image">
          {this.contentData.videoPreview &&
            this.contentData.videoPreview.file &&
            !isMobile(this.props.currentBreakpoint, `tablet`) && (
              <Lazy>
                <Player
                  ref="videoPreview"
                  loop
                  muted
                  playsInline
                  fluid={false}
                  width={`100%`}
                  height={`100%`}
                  className={`content__preview__image__video_preview ${
                    this.state.videoPreviewClassName
                  }`}
                  style={this.state.videoPreviewStyle}
                  src={this.contentData.videoPreview.file.url}
                />
              </Lazy>
            )}
          {/* display hover image only when has no video and desktop */}
          {this.contentData.mainImageHover &&
            !this.contentData.videoPreview &&
            !isMobile(this.props.currentBreakpoint, `tablet`) && (
              <div className="content__preview__image__hover">
                <FigureBox
                  format={this.props.boxFormat}
                  backgroundPosition={this.backgroundPosition()}
                  source={this.contentData.mainImageHover}
                />
              </div>
            )}
          <FigureBox
            format={this.props.boxFormat}
            backgroundPosition={this.backgroundPosition()}
            source={this.contentData.mainImage}
            effect={this.props.effect}
            type={this.props.figuresType}
          />
        </figure>
      )
    } else {
      return <figure>no figure...</figure>
    }
  }

  contentTypeURLPrefix = (type, data) => {
    switch (type) {
      case `work`:
        return `work`
        break
      case `news`:
        return data.displayOnWorking ? `culture` : `news`
        break
      case `culture`:
        return `culture`
        break
      case `jobs`:
        return `jobs`
        break
      case `qAndA`:
        return `culture/q&a`
        break
      default:
        return ``
    }
  }

  readMoreText = () => {
    switch (this.props.contentType) {
      case `work`:
        return `View work`
        break
      case `news`:
        return `Read the story`
        break
      default:
        return `Read more`
        break
    }
  }

  render() {
    // link to attribute handling
    let WrapElement = `a`
    let attributes = {}

    // if link is external, add href and target value
    if (this.contentData.externalLink) {
      attributes = {
        ...{
          href: this.contentData.externalLink,
          target: `_blank`,
        },
      }
    } else {
      WrapElement = Link
      attributes = {
        to: `/${this.contentTypeURLPrefix(
          this.props.contentType,
          this.contentData
        )}/${this.contentData.slug}`,
      }
    }
    
    return (
      <article
        className={`content__preview ${this.props.layout} ${
          this.props.contentType
        } ${this.props.visible} ${this.state.hoverClassName}`}
      >
        <div className="content__preview__inner">
          <WrapElement
            {...attributes}
            className="content"
            onMouseMove={e => this.mouseEnter()}
            onMouseLeave={e => this.mouseLeave()}
          >
            <Reveal
              effect={this.props.effect}
              duration={this.props.duration}
              delay={this.props.delay}
            >
              {this.figure()}
            </Reveal>
            <ContentPreviewInfos
              contentTitle={this.contentTitle()}
              date={this.props.withDate ? this.contentData.publishDate : ``}
              subheadline={this.contentData.subheadline}
              office={this.contentData.office}
              readMoreText={this.readMoreText()}
              reveals={this.props.reveals}
            />
          </WrapElement>
        </div>
      </article>
    )
  }
}

ContentPreview.defaultProps = {
  contentType: `work`,
  layout: ContentPreviewLayouts.compact,
  bottom: false,
  top: false,
  duration: 1000,
  delay: 125,
  effect: `effects__fade_down`,
  figuresType: `background`,
  reveals: true,
  boxFormat: boxFormats.auto,
  tabIndex: 0,
  withDate: false,
}

ContentPreview.propTypes = {
  contentData: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
}

// export default ContentPreview

// const mapStateToProps = store => {
//   return {
//     PageTransitionState: store.PageTransitionState
//   }
// }

export default connect(
    null,
    {
      setTransitionType,
    }
  )(ContentPreview)
